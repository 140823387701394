<template>
  <section class="sponsored-psychics">
    <div class="section-header">
      <h2>Top-Rated Psychic Advisors from Around the World</h2>
      <p class="subheading">Our trusted advisors are ready to offer you unique insights and guidance.</p>
    </div>
    <div class="psychics-bubble-container">
      <!-- Row 1 -->
      <div class="bubble-row">
        <div
          class="bubble"
          v-for="(psychic, index) in row1"
          :key="index"
          :style="{ backgroundImage: `url(${psychic.img})` }"
        >
          <div class="psychic-info">
            <p class="psychic-category">{{ psychic.category }}</p>
            <p class="psychic-extra-info">{{ psychic.extraInfo }}</p>
          </div>
        </div>
      </div>
      <!-- Row 2 -->
      <div class="bubble-row">
        <div
          class="bubble"
          v-for="(psychic, index) in row2"
          :key="index"
          :style="{ backgroundImage: `url(${psychic.img})` }"
        >
          <div class="psychic-info">
            <p class="psychic-category">{{ psychic.category }}</p>
            <p class="psychic-extra-info">{{ psychic.extraInfo }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      row1: [],
      row2: [],
    };
  },
  created() {
    const shuffle = (array) => array.sort(() => Math.random() - 0.5);

    const psychicImages = shuffle(
      Array.from({ length: 18 }, (_, i) => `/assets/psychic_${i}.webp`)
    );

    this.row1 = psychicImages.slice(0, 7).map((img, index) => ({
      name: `Psychic ${index + 1}`,
      img,
      category: this.getRandomCategory(),
      extraInfo: this.getRandomExtraInfo(),
    }));

    this.row2 = psychicImages.slice(7, 14).map((img, index) => ({
      name: `Psychic ${index + 8}`,
      img,
      category: this.getRandomCategory(),
      extraInfo: this.getRandomExtraInfo(),
    }));
  },
  methods: {
    getRandomCategory() {
      const categories = ['Love', 'Career', 'Spirituality', 'Finance', 'Family'];
      return categories[Math.floor(Math.random() * categories.length)];
    },
    getRandomExtraInfo() {
      const extraInfo = [
        '5+ years experience',
        'Highly rated advisor',
        'Certified Psychic',
        'Expert in Tarot reading',
        'Available 24/7',
      ];
      return extraInfo[Math.floor(Math.random() * extraInfo.length)];
    },
  },
};
</script>

<style scoped>
.sponsored-psychics {
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #2a093a, #3d0d57);
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  color: #fff;
}

.section-header {
  margin-bottom: 3rem;
}

.section-header h2 {
  font-size: 2rem;
  color: #ffcd00;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.subheading {
  font-size: 1.1rem;
  color: #db8fff;
  margin-top: 0.5rem;
}

.psychics-bubble-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bubble-row {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  margin-bottom: 3rem;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  max-height: 250px;
  scroll-snap-type: x mandatory; /* Enables snap scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth touch scroll */
  scrollbar-width: none; /* Hides scrollbar in Firefox */
}

.bubble-row::-webkit-scrollbar {
  display: none; /* Hides scrollbar in WebKit browsers */
}

.bubble {
  background-color: #16041e;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  width: 160px;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  position: relative;
  scroll-snap-align: start; /* Bubbles snap into place */

}

.bubble:hover {
  transform: scale(1.1);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.psychic-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: rgba(0, 0, 0, 0.6);
  text-align: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.bubble:hover .psychic-info {
  visibility: visible;
  opacity: 1;
}

.psychic-category {
  font-size: 1rem;
  color: #db8fff;
  font-weight: normal;
  margin-bottom: 3px;
}

.psychic-extra-info {
  font-size: 0.9rem;
  color: #cfcfcf;
}

@media (max-width: 768px) {
  .sponsored-psychics {
    padding: 3rem 1rem;
  }

  .section-header h2 {
    font-size: 1.5rem;
  }

  .subheading {
    font-size: 1rem;
  }

  .bubble-row {
    gap: 15px;
    margin-bottom: 2rem;
  }

  .bubble {
    width: 120px;
    height: 120px;
  }

  .psychic-info {
    font-size: 0.8rem;
  }
}
</style>
