// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import SettingsModule from '../dashboard/SettingsModule.vue';
import PsychicRegistrationModule from '../components/home/PsychicRegistrationModule.vue';
import HomeModule from '../components/home/HomeModule.vue';
import AppDashboard from '@/dashboard/AppDashboard.vue';
import RegistrationSuccess from '@/dashboard/RegistrationSuccess.vue';

const routes = [
  { path: '/',  component: HomeModule, meta: { isHomePage: true } }, // Redirect to Dashboard by default    
  { path: '/psychic-signup', component: PsychicRegistrationModule }, // Route for the Live Psychics page
  { path: '/dashboard', component: AppDashboard }, // Route for the Dashboard page
  { path: '/settings', component: SettingsModule,}, //meta: { requiresAuth: true }} // Ensure only authorized users can access
  { path: '/registration-success', component: RegistrationSuccess,}, 
  { 
    path: '/cancel', component: HomeModule, 
    beforeEnter: (to, from, next) => {
      // Redirects back to the previous page
      next(from.path || '/');
    }
  },
  { 
    path: '/success', 
    component: AppDashboard, 
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      // Redirect to dashboard
      next('/dashboard');
    }
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
