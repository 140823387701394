<template>
  <div class="psychic-sales-page">
    <header class="header">
      <div class="logo">
        <img src="\openpsychiclogo.png" alt="Logo" class="logo-img" />
        <span>OpenPsychic</span>
      </div>
      <nav class="nav-buttons">
        <a href="/" class="psychic-link">Oop's, Go Back.</a>
        <button @click="toggleAuthModal">Login </button>
      </nav>
      <LoginSignupModule v-if="showLogin" :isOpen="showLogin" @close="closeAuthModal" /> 
    </header>

    <section class="hero">
  <div class="hero-content">
    <h1>Shape Your Future as an OpenPsychic Advisor</h1>
    <p>Step into a platform that values your expertise. No limits, no pay-per-minute, just unlimited possibilities to grow your business and connect with seekers.</p>
    <div class="cta-container">
      <button @click="scrollToSection('subscription')" class="cta-button">Join Us Today</button>
      <button @click="scrollToSection('benefits')" class="cta-button secondary">Discover the Benefits</button>
    </div>
  </div>
</section>
<section id="subscription" class="subscriptions">
  <PsychicRegistration/>
</section>
<section id="registration" class="registration-info">
  <h2>Why OpenPsychic?</h2>
  <div class="info-list">
    <div class="info-item">
      <h3>Set Your Own Rates</h3>
      <p>No platform restrictions. Price your services exactly how you want.</p>
    </div>
    <div class="info-item">
      <h3>No Pay-Per-Minute</h3>
      <p>We don’t limit your earning potential. Charge what you deserve, without the pressure of minute-based payments.</p>
    </div>
    <div class="info-item">
      <h3>Unlimited Earning Potential</h3>
      <p>Whether it’s messages, video sessions, or personalized packages, your income is completely in your control.</p>
    </div>
    <div class="info-item">
      <h3>Fast, Secure Payouts</h3>
      <p>Receive payments quickly with Bitcoin and Stripe. No minimum withdrawals, no delays.</p>
    </div>
  </div>
  <button @click="scrollToSection('subscription')" class="cta-button">Start Your Journey</button>
</section>
<section id="benefits" class="benefits">
  <h2>Your Path to True Freedom Starts Now</h2>
  <p>Forget the hassle of hidden fees, unspoken rules, and the suffocating system of other platforms. OpenPsychic offers **complete freedom**—earn on your terms, set your own rates, and enjoy **limitless earning potential**. No pay-per-minute restrictions. Just you and your expertise.</p>
  <p>With OpenPsychic, you’ll keep all your earnings, enjoy instant, secure payments through Bitcoin or Stripe, and have the flexibility to build your brand without extra fees for visibility. This is where your true success begins. No minimums, no restrictions—just total control over your career and your future.</p>
  <p>It’s time to leave behind outdated systems and take control of your career. **OpenPsychic** empowers you to shape your future. The opportunity for success is **yours for the taking**.</p>
  <button @click="scrollToSection('subscription')" class="cta-button primary">Become an Advisor Now</button>
</section>

    <section class="faq">
  <h2>Frequently Asked Questions</h2>
  <div class="faq-item" v-for="(item, index) in faqs" :key="index">
    <h3 @click="toggleFaq(index)">{{ item.question }}</h3>
    <p :class="{ open: item.open }">{{ item.answer }}</p> <!-- Use v-bind:class to toggle the open class -->
  </div>
</section>

    <footer class="footer">
      <p>&copy; 2025 OpenPsychic. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>

import LoginSignupModule from './LoginModule.vue'; // Adjust path if necessary
import PsychicRegistration from './PsychicRegistration.vue';

export default {
  components: {
    LoginSignupModule,
    PsychicRegistration
  },
  data() {
    return {
      showLogin: false,
      faqs: [
      {
        question: "What is OpenPsychic?",
        answer: "OpenPsychic is the ONLY platform where Psychic Advisors have total freedom. No pay-per-minute traps. No platform interference. You set your prices, create your services, and keep every dollar you earn.",
        open: false
      },
      {
        question: "Why is OpenPsychic a game-changer?",
        answer: "Because here, YOU RUN THE SHOW. No platform tells you how to price your gifts. You negotiate directly with seekers, offer custom services, and cash out whenever you want. No caps. No cuts. No BS.",
        open: false
      },
      {
        question: "How do I become an Advisor?",
        answer: "Click 'Join as a Psychic Advisor' to access your dashboard. Pay the $99.99 activation fee for Real Psychic Protection—it keeps this space safe, secure, and full of serious seekers. Complete onboarding and you’re in.",
        open: false
      },
      {
        question: "What is Real Psychic Protection?",
        answer: "It’s our way of keeping the platform real. The activation fee weeds out fake psychics, protects your business, and drives serious growth so you can make real money.",
        open: false
      },
      {
        question: "How do I get paid?",
        answer: "ANY WAY YOU WANT. We support Bitcoin payouts for fast, secure, and borderless payments. Soon, we’re adding MetaMask wallet integration for even easier crypto tracking. Prefer traditional payouts? Stripe has you covered. NO minimum withdrawals. NO delays. Cash out whenever you want.",
        open: false
      },
      {
        question: "Can I work from my phone?",
        answer: "Of course. Use the OpenPsychic app on Google Play or the website dashboard to run your business anytime, anywhere. Your hustle, your terms.",
        open: false
      },
      {
        question: "Can I offer custom services?",
        answer: "100%. Create anything from VIP readings to exclusive packages. You call the shots and set the prices.",
        open: false
      },
      {
        question: "How much can I make?",
        answer: "There’s NO LIMIT. You decide how much your time and energy are worth. Negotiate directly with clients and stack your earnings.",
        open: false
      },
      {
        question: "How do live session bookings work?",
        answer: "Seekers send booking requests for live sessions, messages, or services. You decide what to accept—no forced schedules, no pressure.",
        open: false
      },
      {
        question: "Why are Bitcoin and MetaMask payouts a big deal?",
        answer: "Because we’re future-proof. Bitcoin gets you paid fast and globally, no middleman. MetaMask integration is coming soon, giving you even more freedom to track and grow your crypto earnings.",
        open: false
      },
      {
        question: "Do I need to use my real name or photo?",
        answer: "We only work with REAL advisors. Be authentic. You can use a professional alias or profile image, but your guidance must be 100% genuine.",
        open: false
      },
      {
        question: "Can I remove negative reviews?",
        answer: "Nope. We keep it real. Handle criticism like a pro and show clients you’re legit.",
        open: false
      },
      {
        question: "What equipment do I need?",
        answer: "Just your phone, internet, and a quiet space. That’s all it takes to start earning.",
        open: false
      },
      {
        question: "What if I need help?",
        answer: "We’re here 24/7. Reach out through the app or email support@openpsychic.com.",
        open: false
      },
      {
        question: "How do I join OpenPsychic?",
        answer: "Click 'Join Now', complete onboarding, and you’re ready to get started. You’re in charge of your schedule, services, and earnings from day one!",
        open: false
      }
    ]
    };
  },
  methods: {
    toggleAuthModal() {
      this.showLogin = !this.showLogin;  // Toggle modal visibility
    },
    closeAuthModal() {
      this.showLogin = false;  // Close modal when clicked
    },
    navigateTo(page) {
      console.log(`Navigating to ${page}`);
    },
    toggleFaq(index) {
      this.faqs[index].open = !this.faqs[index].open;
    },
    scrollToSection(sectionId) {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }
  }
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.psychic-sales-page {
  font-family: 'Poppins', sans-serif;
  color: #333;
  background-color: #f2f2f2;
  width: 100%;
  overflow-x: hidden;
}

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #16041E;
  color: #DB8FFF;
  box-shadow: 0px 2px 5px rgba(219, 143, 255, 0.3);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: 700;
  color: #DB8FFF;
}

.logo-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.logo span {
  font-family: 'Uncial Antiqua', serif;
  font-size: 24px;
  color: #DB8FFF;
  letter-spacing: 1.5px;
  text-shadow: 0 0 10px rgba(219, 143, 255, 0.8), 0 0 20px rgba(219, 143, 255, 0.5);
  font-weight: 400;
  margin-left: 10px;
}

.psychic-link {
  color: #DB8FFF;
  font-size: 12px;
  margin-left: 20px;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  text-shadow: 0 0 8px rgba(219, 143, 255, 0.6);
}

.psychic-link:hover {
  color: #FFCD00;
  text-shadow: 0 0 12px rgba(255, 205, 0, 0.8);
  transform: scale(1.05);
}

.nav-buttons button {
  background: none;
  border: 2px solid #3D005A;
  color: #3D005A;
  padding: 10px 25px;
  margin-left: 10px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav-buttons button:hover {
  background-color: #DB8FFF;
  color: #fff;
}

.hero {
  width: 100%;
  height: 100vh;
  background: linear-gradient(rgba(14, 11, 22, 0.5), rgba(14, 11, 22, 0.5)), center/cover no-repeat;
  background-image: radial-gradient(circle at top, #c400ff, #33004d);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px 10%;
}

.hero h1 {
  font-size: 64px;
  margin-bottom: 20px;
}

.hero p {
  font-size: 22px;
  margin-bottom: 40px;
  max-width: 800px;
}

.cta-button {
  background-color: #ffca28;
  color: #333;
  padding: 18px 50px;
  border-radius: 30px;
  border: none;
  font-size: 20px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.cta-button:hover {
  transform: scale(1.1);
}

.pricing, .features, .faq {
  padding: 100px 10%;
  text-align: center;
}

.plans {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 40px;
}

.plan {
  background-color: #fff;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 300px;
  transition: transform 0.3s ease;
}

.plan:hover {
  transform: translateY(-10px);
}

.faq {
  background-color: #2a093a;
  padding: 40px 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  color: #ECC3FF;
}

.faq h2 {
  text-align: center;
  font-size: 2rem;
  color: #FFCD00;
  margin-bottom: 30px;
}

.faq-item {
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 8px;
  background-color: #3d0d57;
  transition: background-color 0.3s ease;
}

.faq-item h3 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #DB8FFF;
  cursor: pointer;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: color 0.3s ease;
}

.faq-item h3:hover {
  color: #FFCD00;
}

.faq-item p {
  text-align: left;
  font-size: 1rem;
  color: #A6EFCEFF;
  margin-top: 10px;
  transition: opacity 0.3s ease;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.faq-item p.open {
  opacity: 1;
  max-height: 500px;
}

.footer {
  background-color: #16041E;
  color: #DB8FFF;
  height: 40px;
  padding: 10px 20px;
  position: fixed;
  bottom: 0;
  margin-top: 10px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.registration-info {
  background-color: #f9f9f9;
  padding: 80px 10%;
  text-align: center;
}

.registration-info h2 {
  font-size: 3rem;
  margin-bottom: 40px;
  color: #16041E;
  text-shadow: 0 0 10px rgba(219, 143, 255, 0.8);
}

.info-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 50px;
}

.info-item {
  background-color: #fff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  width: 240px;
  transition: transform 0.3s ease;
}

.info-item:hover {
  transform: translateY(-5px);
}

.info-item h3 {
  font-size: 1.5rem;
  color: #3D005A;
  margin-bottom: 15px;
}

.info-item p {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
}

.cta-button.primary {
  background-color: #ffca28;
  color: #333;
  padding: 18px 50px;
  border-radius: 30px;
  border: none;
  font-size: 20px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.cta-button.primary:hover {
  transform: scale(1.1);
}

#benefits {
  background-color: #2a093a;
  padding: 100px 10%;
  text-align: center;
  color: #ECC3FF;
}

#benefits h2 {
  font-size: 3rem;
  margin-bottom: 40px;
  color: #FFCD00;
  text-shadow: 0 0 10px rgba(255, 205, 0, 0.8);
}

#benefits p {
  font-size: 1.2rem;
  color: #A6EFCEFF;
  margin-bottom: 50px;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 36px;
  }

  .plans {
    flex-direction: column;
    align-items: center;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-buttons {
    margin-top: 10px;
    width: 100%;
    justify-content: flex-end;
    gap: 10px;
  }
}
</style>
