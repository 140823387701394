<template>
    <div id="app">
      <section class="psychic-registration">
        <h1 class="main-heading">This is Your Moment to Shine</h1>
        <h2 class="sub-heading">Unlock the Ultimate Power of OpenPsychic—Where True Freedom Awaits</h2>
        <h1 class="highlight-heading">Join Our Community of True Psychic Advisors</h1>
  
        <div class="plan">
          <h3 class="plan-title">Psychic Advisor Registration</h3>
          <p class="price">Activate your account for just $99.99 for 2 years.</p>
  
          <button @click="startCheckout" class="cta-button">Start Your Journey</button>
  
          <p class="plan-includes">Here’s what you get as a Psychic Advisor:</p>
          <ul class="benefit-list">
            <li><strong>Set Your Own Rates:</strong> No limits—price your services your way.</li>
            <li><strong>Keep Every Dollar:</strong> 100% control over your earnings—no pay-per-minute fees.</li>
            <li><strong>Instant Payments:</strong> Fast, secure payouts via Bitcoin, Stripe, and more—global and seamless.</li>
            <li><strong>Build Your Brand:</strong> Connect with seekers who truly value your gifts.</li>
            <li><strong>Exclusive Access:</strong> Enjoy priority scheduling and VIP access to top seekers.</li>
          </ul>
          <p class="extra-info">No hidden fees. No fine print. Just pure opportunity to thrive.</p>
          <a href="/" class="learn-more">Learn More</a>
        </div>
      </section>
  
      <footer>
        <p>All plans subject to applicable taxes and fees.</p>
      </footer>
    </div>
  </template>
  <script>
  import { loadStripe } from "@stripe/stripe-js";
  
  export default {
    name: 'App',
    data() {
      return {
        stripePromise: loadStripe("pk_live_51QKSz3J1NhX9kaJ0eGoIERh0iBQsqjNC9WvOvwUhnec9zSwSDyZz6ELhWWldFaO6AejMGnpYcgZehswg18tcdvKz00ZfGGN7Nb"), // Use your Stripe public key here
      };
    },
    methods: {
      navigateTo(page) {
        console.log(`Navigating to ${page}`);
      },
      async startCheckout() {
        const stripe = await this.stripePromise;
  
        // Call your backend to create a checkout session
        const response = await fetch("/api/create-subscription-checkout-session", {
          method: "POST",
        });
  
        const session = await response.json();
  
        // Redirect to Stripe checkout
        const { error } = await stripe.redirectToCheckout({
          sessionId: session.id,
        });
  
        if (error) {
          console.error("Error redirecting to checkout: ", error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    text-align: center;
    color: #ECC3FF;
    background-color: #2a093a;
    padding: 20px 10px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .main-heading {
    font-size: 2rem;
    color: #FFCD00;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .sub-heading {
    font-size: 1.3rem;
    margin-bottom: 15px;
    color: #FFCD00;
  }
  
  .highlight-heading {
    font-size: 1.8rem;
    font-weight: bold;
    color: #FFCD00;
    margin-bottom: 20px;
  }
  
  .plan {
    background-color: transparent;
    padding: 20px;
    border: 2px solid #6a4e94;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    max-width: 100%;
    margin: 0 auto;
  }
  
  .plan-title {
    font-size: 1.2rem;
    color: #FFCD00;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .price {
    font-size: 1rem;
    color: #d3b1e5;
    margin-bottom: 15px;
  }
  
  .cta-button {
    background-color: #FFCD00;
    color: #2a093a;
    border: none;
    padding: 12px 30px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 15px;
  }
  
  .cta-button:hover {
    background-color: #e6b800;
  }
  
  .benefit-list li {
    font-size: 0.9rem;
    color: #fff;
    margin-bottom: 5px;
  }
  
  .extra-info, .learn-more {
    font-size: 0.8rem;
    color: #d3b1e5;
  }
  
  footer {
    padding: 10px;
    font-size: 0.8rem;
    color: #d3b1e5;
  }
  
  @media (min-width: 600px) {
    .main-heading {
      font-size: 2.5rem;
    }
    .sub-heading {
      font-size: 1.5rem;
    }
    .highlight-heading {
      font-size: 2rem;
    }
    .plan {
      max-width: 450px;
    }
    .cta-button {
      font-size: 1.1rem;
      padding: 12px 35px;
    }
  }
  
  @media (max-width: 600px) {
    .main-heading {
      font-size: 1.5rem;
    }
    .sub-heading {
      font-size: 1.2rem;
    }
    .highlight-heading {
      font-size: 1.6rem;
    }
    .plan {
      padding: 15px;
      margin: 10px;
    }
    .cta-button {
      font-size: 1rem;
      padding: 12px 25px;
    }
    .plan-title {
      font-size: 1.1rem;
    }
    .price {
      font-size: 0.9rem;
    }
    .benefit-list li {
      font-size: 0.8rem;
    }
  }
  </style>
  