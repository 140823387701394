<template>
  <div class="container">
    <button class="logout-btn" @click="logout">Logout</button>
    <h1>We're Building a Better <span class="brand">OpenPsychic</span>!</h1>
    <p>Your account is being created. Please check your email for updates and next steps.</p>
    <p>Stay tuned and follow us for the latest news and updates!</p>
    <div class="social-links">
      <a href="https://www.tiktok.com/@OpenPsychic" target="_blank">Follow us on TikTok</a>
      <a href="https://www.youtube.com/@OpenPsychic" target="_blank">Subscribe to our YouTube</a>
      <a href="https://www.instagram.com/OpenPsychic" target="_blank">Follow us on Instagram</a>
    </div>
  </div>
</template>

<script>
import { getAuth, signOut } from 'firebase/auth';
import { useRouter } from 'vue-router';

export default {
  name: 'RegistrationSuccess',
  setup() {
    const router = useRouter();
    const logout = () => {
      const auth = getAuth();
      signOut(auth).then(() => {
        router.push('/');
      }).catch((error) => {
        console.error('Logout error:', error);
      });
    };
    return { logout };
  }
}
</script>

<style scoped>
.container {
  font-family: 'Poppins', sans-serif;
  text-align: center;
  background: #ffffff;
  color: #5e2d89;
  padding: 40px 20px;
  max-width: 600px;
  margin: 80px auto;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}
.logout-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: #5e2d89;
  font-size: 1em;
  cursor: pointer;
  font-weight: bold;
  transition: color 0.3s ease;
}
.logout-btn:hover {
  color: #8a2be2;
}
h1 {
  font-size: 2em;
  font-weight: bold;
}
.brand {
  color: #8a2be2;
}
p {
  font-size: 1.1em;
  margin: 15px 0;
}
.social-links {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
.social-links a {
  color: #8a2be2;
  font-size: 1.2em;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease;
}
.social-links a:hover {
  color: #5e2d89;
}
</style>
