<template>
  <div id="AppDashboard">
    <div class="dashboard-container">
      <div class="left-column">
        <!-- Left column content (empty) -->
      </div>
      <div class="center-column">
        <!-- Center column content -->
        <h1>Welcome to Your Dashboard</h1>
        <p>This is the center content area. You can add more features here.</p>
      </div>
      <div class="right-column">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AppDashboard',
  components: {
  },
};
</script>

<style scoped>
/* Reset margins and paddings for full-page layout */
html, body, #AppDashboard {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Prevent scrollbars unless content overflows */
}

.dashboard-container {
  display: flex;
  height: 100%;
  width: 100%;
  background-color:  #6A4C7D; /* Slightly lighter gray */
}

.left-column {
  width: 15%; /* Fixed width for the left column */
  background-color: #1F0322;; /* Medium Purple */

}

.center-column {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto; /* Enable scrolling if content overflows */
}

.right-column {
  width: 300px; /* Fixed width for the right column */
  padding: 10px;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  overflow-y: auto; /* Enable scrolling for long chat content */
}
</style>
